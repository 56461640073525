import React from 'react';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import injectionEngine from '@carwow/injection-engine';
import rules from '@carwow/injection-default-rules-set';
import { getArticleBody } from 'SharedPartialsLocal/getArticleBody';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';

import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';
import { getSiteBlockGroup } from 'Helpers/getSiteBlockGroup';

import { basicPagePropsShape } from './props-shape';

import 'Styles/components/templates/_BasicPage.scss';

export const BasicPage = (props) => {
  const {
    config: {
      breakpoints,
      globalSettings: {
        newsletterLink,
        promoBoxSettings,
        adSettings,
      },
      newsletter: newsletterSettings,
    },
    layoutData: {
      page: {
        associatedContent,
        body,
        breadcrumbs,
        isSponsored,
        kicker,
        metaData,
        monetising,
        subtitle,
        title,
        layoutType,
        hideTitle,
      },
    },
  } = props;

  const isLandingPage = layoutType === 'landingPage';

  const promoBox = { ...promoBoxSettings, extraClassNames: { '-inline': true } };
  const newsletter = {
    props: {},
    portalNewsletterUrl: newsletterLink,
    configNewsletter: newsletterSettings,
    additionalProps: { extraClassNames: { '-inline': true } },
  };

  const bodyInjected = injectionEngine({
    body,
    props: {
      adSettings,
      breakpoints,
      metaData,
      monetising,
      newsletterConfig: newsletter,
      promoBoxSettings: promoBox,
    },
    rules: [
      ...rules,
    ],
  });

  const ArticleBody = getArticleBody({
    bodyInjected,
    props,
    template: 'Basic',
  });
  return (
    <div className="polaris__body">
      <main className="polaris__main" id="main">

        <div className="polaris__core-content polaris__content">

          {!isLandingPage && getBreadcrumbs({ breadcrumbs, isSponsored, kicker })}

          {!hideTitle && (
            <Heading size={1} extraClassNames={{ '-content-title': true }}>
              {title}
            </Heading>
          )}

          {!isLandingPage && (
          <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
            {subtitle}
          </Heading>
          )}

        </div>

        { ArticleBody }

        {!isLandingPage && (
          <div className="polaris__core-content polaris__content">
            {getArticleGroup({
              title: 'Recommended',
              content: getArticleGroupContent({ related: associatedContent, key: 'recommended' }),
              articlesPerRow: 4,
              articleCardProps: {
                datePosition: 'meta',
                excerpt: null,
                headingSize: 4,
                rating: 5,
                showRating: true,
              },
            })}
            {getMostPopularArticleGroup({ associatedContent, nativeAd: 'native_article' }) }
            {getSiteBlockGroup({
              associatedContent,
              title: 'Tips & advice',
              articlesPerRow: 4,
              articleCardProps: {
                excerpt: null, datePosition: 'meta', headingSize: 4,
              },
            })}
            {getSiteBlockGroup({
              associatedContent,
              title: 'Best cars',
              articlesPerRow: 4,
              articleCardProps: {
                excerpt: null, datePosition: 'meta', headingSize: 4,
              },
            })}
          </div>
        )}
      </main>
    </div>
  );
};

BasicPage.propTypes = {
  ...basicPagePropsShape,
};
